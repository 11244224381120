<p-sidebar [visible]="(leftSidebarVisible | async)!" [showCloseIcon]="false" [fullScreen]="false" styleClass="w-full">
  <ng-template pTemplate="header">
    <div id="left-sidebar-header">
      <img id="logo" src="images/logos/logo.png" height="80">
      {{businessName}}
      <p-button icon="pi pi-times" styleClass="p-button-rounded" (click)="onCloseLeftSidebarClick()"></p-button>
    </div>
  </ng-template>
  <ng-template pTemplate="content"><aim-left-sidebar></aim-left-sidebar></ng-template>
  <ng-template pTemplate="footer">Footer Content</ng-template>
</p-sidebar>

@if (isMobile$ | async) {
  <div class="flex justify-content-between align-items-center primary-bg-color p-2">
    <div class="flex align-items-center">
      <p-button icon="pi pi-bars" styleClass="p-button-rounded p-button-text p-button-lg"
        (click)="onShowLeftSidebarClick()"></p-button>
      <a><img (click)="goToHome()" src="images/logos/white-logo.png" height="50" class="mr-2" /></a>
    </div>
    <div></div>
    @if (showCart) {
      <div>
        <p-button icon="pi pi-user" styleClass="p-button-rounded p-button-text p-button-lg"
          (onClick)="onAccountClick($event)"></p-button>
        <p-button icon="pi pi-shopping-cart" [badge]="itemsInCart ? itemsInCart.toString() : ''"
          styleClass="p-button-rounded p-button-text p-button-lg" [routerLink]="'/' + routes.CART">
        </p-button>
      </div>
    }
  </div>
}
@else {
  <div id="desktop-template">
    <div class="flex justify-content-between align-items-center primary-bg-color p-3">
      <div class="flex">
        <img (click)="goToHome()" src="images/logos/white-logo.png" height="80" class="mr-2 logo-style" />
        <span class="text-decoration-none align-items-center flex primary-text-color mr-5 text-3xl">Code Echo</span>
        <a [href]="phoneUrl" class="text-decoration-none align-items-center flex primary-text-color">
        <i class="pi pi-phone md-font-size mr-2 "></i>{{phone}}</a>
      </div>

      <div class="flex align-items-center">
        @if (showCart) {
          <div class="flex gap-1 p-1 pl-3 pr-3 m-1 border-round-left-3xl border-round-right-3xl pointer header-item"
               (click)="onAccountClick($event)" (mouseenter)="onAccountHover($event)">
          <i class="pi pi-user pr-2 md-font-size"></i>
          <div class="flex flex-column">
            <span class="primary-text-color">Welcome</span>
            <span class="font-bold primary-text-color">{{accountText}}</span>
          </div>
        </div>
        }
        @if (showCart) {
          <div class="flex gap-1 p-1 pl-3 pr-3 m-1 border-round-left-3xl border-round-right-3xl pointer header-item" [routerLink]="'/' + routes.CART">
          <i class="pi pi-shopping-cart pr-2 md-font-size"></i>
          @if (itemsInCart) {
            <div class="flex flex-column">
              <span class="primary-text-color">{{itemsInCart}} items</span>
              <span class="font-bold primary-text-color">{{cartTotal$ | async | currency}}</span>
            </div>
          }
          @else {
            <span class="font-bold align-self-end sm-font-size primary-text-color">Cart</span>
          }
        </div>
        }
      </div>
    </div>

    <p-menubar [model]="menuItems" [autoDisplay]="true" [autoHide]="true" [autoHideDelay]="150">
      <ng-template pTemplate="start">
      </ng-template>
      <ng-template pTemplate="end">
        <span class="p-input-icon-right">
          <i class="pi pi-search pointer" (click)="onSearch()"></i>
          <input type="text" [(ngModel)]="searchTerm" pInputText placeholder="Search" class="w-full"
            (keydown.enter)="onSearch()" />
        </span>
      </ng-template>
    </p-menubar>
  </div>
}

<p-overlayPanel #account [showCloseIcon]="true" class="account-overlay-header">
  <aim-header-account  (closeOverlay)="onCloseAccountOverlay()"></aim-header-account>
</p-overlayPanel>

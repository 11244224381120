import { Component, ChangeDetectionStrategy, ViewChild, OnInit } from '@angular/core';
import { NgxAimHeaderClass } from 'ngx-aim';
import { RouterLink } from '@angular/router';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { staticMenuItems } from '../static-menu-items';
import { HeaderAccountComponent } from './header-account/header-account.component';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { MenubarModule } from 'primeng/menubar';
import { AsyncPipe, CurrencyPipe } from '@angular/common';
import { LeftSidebarComponent } from '../left-sidebar/left-sidebar.component';
import { ButtonModule } from 'primeng/button';
import { MenuItem, SharedModule } from 'primeng/api';
import { SidebarModule } from 'primeng/sidebar';

@Component({
  selector: 'aim-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SidebarModule,
    SharedModule,
    ButtonModule,
    LeftSidebarComponent,
    RouterLink,
    MenubarModule,
    FormsModule,
    InputTextModule,
    OverlayPanelModule,
    HeaderAccountComponent,
    AsyncPipe,
    CurrencyPipe,
  ]
})
export class HeaderComponent extends NgxAimHeaderClass implements OnInit {
  @ViewChild('account')
  override accountPanel: OverlayPanel | undefined;

  override menuItems: MenuItem[] = [
    {
      label: 'Products',
      items: this.appState.categories,
    },
    ...staticMenuItems,
  ];

  constructor() {
    super();
  }

  ngOnInit() {
    this.init();
  }
}

import { MenuItem } from 'primeng/api';
import { PublicRoutes } from 'ngx-aim';
import { CustomerSpecificRoutes } from '../customer-specific-routes';

export const staticMenuItems: MenuItem[] = [
  {
    label: 'Rentals',
    routerLink: `/${PublicRoutes.RENTALS}`,
  },
  {
    label: 'About Us',
    routerLink: `/${PublicRoutes.ABOUT_US}`,
  },
  {
    label: 'Contracts',
    routerLink: `/${PublicRoutes.CONTRACTS}/${PublicRoutes.SELECT_SCHOOL}`,
  },
  {
    label: 'Contact Us',
    routerLink: `/${PublicRoutes.CONTACT_US}`,
  },
  {
    label: 'Teachers',
    routerLink: `/${PublicRoutes.POSTS}/${CustomerSpecificRoutes.TEACHERS}`,
  },
];
